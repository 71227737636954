<script setup>
import { ref, computed, watchEffect } from "vue";
import { ChevronRight } from "lucide-vue";
import { useIntervalFn } from "@vueuse/core";
import { formatRelative, format } from "date-fns";
import { enGB } from "date-fns/locale";
import { cn } from "@/utils/lib";
import usePfpStore from "@/pinia/pfp";
import _store from "@/store";
import TransitionExpand from "@/components/pfp/transition-expand/TransitionExpand.vue";
import Tooltip from "@/components/pfp/ui/tooltip/Tooltip.vue";
import SourceTags from "@/components/pfp/source-tags/SourceTags.vue";
import Label from "@/components/pfp/ui/label/Label.vue";

const emit = defineEmits(["row:open"]);

const props = defineProps({
    result: {
        type: Object,
        required: true,
    },
});

const locale = {
    ...enGB,
    formatRelative: (token) => {
        return {
            lastWeek: "'Last' eeee 'at' p",
            yesterday: "'Yesterday at' p",
            today: "'Today at' p",
            tomorrow: "'Tomorrow at' p",
            nextWeek: "eeee 'at' p",
            other: "P p",
        }[token];
    },
};

const { pause, resume, isActive } = useIntervalFn(() => {
    store.fetchResult(props.result.id);
}, 5000);

const store = usePfpStore();
const rowOpen = ref(false);

const noResults = computed(() => {
    return !props.result.results?.length > 0;
});

const isTeamOwner = computed(() => {
    return _store.getters["auth/isTeamOwner"];
});

watchEffect(() => {
    if (!rowOpen.value && isActive) pause();

    if (rowOpen.value && props.result.loading) {
        resume();
        return;
    }

    pause();
});

async function toggleRow() {
    rowOpen.value = !rowOpen.value;

    emit("row:open", rowOpen.value);

    if (!rowOpen.value) return;

    if (props.result.loading) {
        store.fetchResult(props.result.id);
    }
}
</script>

<template>
    <div
        class="tw-w-full tw-rounded-xl tw-border tw-border-border tw-p-4 md:tw-border-none md:tw-p-0"
        v-if="result"
        :data-result="result.id"
    >
        <!-- TABLET ABOVE -->
        <div
            class="tw-grid-cols-[1fr_4fr_8fr_5fr_3fr_3fr] tw-transition-colors hover:tw-bg-muted tw-px-3 tw-py-4 tw-rounded-xl tw-cursor-pointer tw-items-center tw-justify-center tw-hidden md:tw-grid tw-relative"
            @click="toggleRow"
        >
            <p
                v-if="isTeamOwner"
                class="tw-text-xs tw-text-muted-foreground tw-absolute tw-bottom-1 tw-left-11"
            >
                Searched by <span>{{ result.user.name }}</span> ({{
                    result.team.name
                }})
            </p>

            <div class="tw-flex tw-items-center">
                <ChevronRight
                    :class="
                        cn(
                            'tw-w-5 tw-h-5 tw-text-foreground tw-transition-transform',
                            rowOpen && 'tw-rotate-90'
                        )
                    "
                />
            </div>

            <div class="tw-text-sm tw-pr-1">{{ result.reference }}</div>

            <div class="tw-text-sm">{{ result.query }}</div>

            <div class="group tw-text-center" v-if="!props.result.loading">
                <p
                    v-if="result.sourcesMatched?.length === 0"
                    class="tw-text-sm tw-text-muted-foreground"
                >
                    No sources matched
                </p>
                <Tooltip
                    hover
                    :hover-intent="200"
                    v-else-if="result.sourcesMatched.length > 1"
                    class="tw-block tw-w-full"
                >
                    <template #trigger class="w-full">
                        <SourceTags
                            :data="[result.sourcesMatched?.[0]]"
                            class="tw-inline-flex"
                        />
                        <span
                            class="tw-text-xs tw-text-muted-foreground group-hover:tw-text-foreground tw-inline-block tw-text-left"
                            >+ {{ result.sourcesMatched.length - 1 }} more</span
                        >
                    </template>
                    <template #content>
                        <div class="tw-flex tw-gap-2">
                            <SourceTags :data="result.sourcesMatched" />
                        </div>
                    </template>
                </Tooltip>
                <SourceTags
                    :data="result.sourcesMatched"
                    class="tw-inline-flex"
                    v-else
                />
            </div>

            <p class="tw-text-sm tw-text-center" v-if="!props.result.loading">
                <Tooltip hover :hover-intent="400" class="tw-block tw-w-full">
                    <template #trigger class="w-full">
                        <span>{{
                            format(result.startedDate, "dd/MM/yy")
                        }}</span>
                    </template>
                    <template #content>
                        {{
                            formatRelative(result.startedDate, new Date(), {
                                locale,
                            })
                        }}
                    </template>
                </Tooltip>
                <span class="tw-text-sm tw-block tw-text-muted-foreground">{{
                    format(result.startedDate, "p")
                }}</span>
            </p>

            <p class="tw-text-sm tw-text-center" v-if="!props.result.loading">
                <Tooltip hover :hover-intent="400" class="tw-block tw-w-full">
                    <template #trigger class="w-full">
                        <span>{{
                            format(result.completedDate, "dd/MM/yy")
                        }}</span>
                    </template>
                    <template #content>
                        {{
                            formatRelative(result.completedDate, new Date(), {
                                locale,
                            })
                        }}
                    </template>
                </Tooltip>
                <span class="tw-text-sm tw-block tw-text-muted-foreground">{{
                    format(result.completedDate, "p")
                }}</span>
            </p>

            <p
                v-if="props.result.loading"
                class="tw-absolute tw-right-2 tw-text-sm tw-bg-muted tw-p-3 tw-rounded-xl"
            >
                Your search has been started, open to view results.
            </p>
        </div>

        <!-- MOBILE CARDS -->
        <div
            class="md:tw-hidden tw-flex tw-flex-col tw-gap-y-6 tw-px-2 tw-py-4"
            @click="toggleRow"
        >
            <div class="tw-flex tw-gap-x-4">
                <div class="tw-flex tw-items-center">
                    <ChevronRight
                        :class="
                            cn(
                                'tw-w-5 tw-h-5 tw-text-foreground tw-transition-transform',
                                rowOpen && 'tw-rotate-90'
                            )
                        "
                    />
                </div>

                <div>
                    <Label>Reference</Label>
                    <div>{{ result.reference }}</div>
                </div>
            </div>

            <div class="tw-ml-9">
                <Label>Query</Label>
                <div>{{ result.query }}</div>
            </div>

            <p
                v-if="props.result.loading"
                class="tw-text-sm tw-bg-muted tw-p-3 tw-rounded-xl"
            >
                Your search has been started, open to view results.
            </p>

            <div class="tw-ml-9" v-if="!props.result.loading">
                <Label>Sources matched</Label>
                <SourceTags :data="result.sourcesMatched" />
                <p v-if="result.sourcesMatched?.length === 0">None</p>
            </div>

            <div
                class="tw-flex tw-gap-x-6 tw-ml-9"
                v-if="!props.result.loading"
            >
                <div>
                    <Label>Started</Label>
                    <p>
                        <Tooltip
                            hover
                            :hover-intent="400"
                            class="tw-block tw-w-full"
                        >
                            <template #trigger class="w-full">
                                <span>{{
                                    format(result.startedDate, "dd/MM/yyyy")
                                }}</span>
                            </template>
                            <template #content>
                                {{
                                    formatRelative(
                                        result.startedDate,
                                        new Date(),
                                        { locale }
                                    )
                                }}
                            </template>
                        </Tooltip>
                        <span
                            class="tw-text-sm tw-block tw-text-muted-foreground"
                            >{{ format(result.startedDate, "p") }}</span
                        >
                    </p>
                </div>

                <div>
                    <Label>Completed</Label>
                    <p>
                        <Tooltip
                            hover
                            :hover-intent="400"
                            class="tw-block tw-w-full"
                        >
                            <template #trigger class="w-full">
                                <span>{{
                                    format(result.completedDate, "dd/MM/yyyy")
                                }}</span>
                            </template>
                            <template #content>
                                {{
                                    formatRelative(
                                        result.completedDate,
                                        new Date(),
                                        { locale }
                                    )
                                }}
                            </template>
                        </Tooltip>
                        <span
                            class="tw-text-sm tw-block tw-text-muted-foreground"
                            >{{ format(result.completedDate, "p") }}</span
                        >
                    </p>
                </div>
            </div>
            <p
                v-if="isTeamOwner"
                class="tw-text-xs tw-text-muted-foreground tw-ml-9"
            >
                Searched by <span>{{ result.user.name }}</span> ({{
                    result.team.name
                }})
            </p>
        </div>

        <TransitionExpand>
            <div v-if="rowOpen">
                <div class="tw-py-2 tw-flex tw-flex-col tw-gap-y-2">
                    <!-- loading -->
                    <p
                        v-if="props.result.loading"
                        class="tw-flex tw-items-center mx-auto tw-text-muted-foreground"
                    >
                        <svg
                            class="tw-animate-spin tw-mr-2 tw-h-5 tw-w-5 tw-text-muted-foreground"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="tw-opacity-50"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="tw-opacity-100"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Loading...
                    </p>
                    <!-- no results -->
                    <p
                        v-if="noResults && !props.result.loading"
                        class="tw-text-center tw-text-muted-foreground"
                    >
                        No results found
                    </p>
                    <!-- result -->
                    <slot />
                </div>
            </div>
        </TransitionExpand>
    </div>
</template>
