<script setup>
import { ref } from 'vue';
import { FilterIcon } from 'lucide-vue';
import { useDebounceFn } from '@vueuse/core';
import { SOURCE } from '@/components/pfp/const';
import usePfpStore from '@/pinia/pfp';
import TransitionExpand from '@/components/pfp/transition-expand/TransitionExpand.vue';
import ResultsFilter from '@/components/pfp/results-table/ResultsFilter.vue';
import ResultsHeader from '@/components/pfp/results-table/ResultsHeader.vue'
import ResultsRow from '@/components/pfp/results-table/ResultsRow.vue'
import ProfileModule from '@/components/pfp/results-table/modules/profile/ProfileModule.vue';
import GumtreeModule from '@/components/pfp/results-table/modules/gumtree/GumtreeModule.vue';
import PiplModule from '@/components/pfp/results-table/modules/pipl/PiplModule.vue';
import AddSourceModule from '@/components/pfp/results-table/modules/add-source/AddSourceModule.vue';
import AddMultiSourceModule from '@/components/pfp/results-table/modules/add-source/AddMultiSourceModule.vue';
import AdditionalInfoModule from '@/components/pfp/results-table/modules/additional-info/AdditionalInfoModule.vue';
import RelatedEmailsNumbersModule from '@/components/pfp/results-table/modules/related-emails-numbers/RelatedEmailsNumbersModule.vue';
import Separator from '@/components/pfp/ui/separator/Separator.vue';
import Button from '@/components/pfp/ui/button/Button.vue';

const showFilters = ref(false);
const store = usePfpStore();

function handleSort(column, direction) {
  store.filters.updateSort(column, direction);
}

const handleSearch = useDebounceFn(function(query) {
  store.filters.updateSearch(query);
}, 250);

function handleSources(sources) {
  store.filters.updateSources(sources);
}

function handleRange(which, range) {
  store.filters.updateRange(which, range);
}

function handleAddSources(sources, result) {
  const isEmail = result.query.includes('@');

  const data = {
    ...(isEmail && { email: result.query }),
    ...(!isEmail && { phone: result.query }),
    reference: result.reference,
    sources: sources,
  }

  store.requestSearch(data, result.id);
}

function getAddSources(result) {
  const isEmail = result.query.includes('@');

  const sources = result.data.addSource.filter((source) => {
    return source !== SOURCE.LEAKED_DATASETS_OLD;
  });

  if (isEmail) {
    return sources.filter((source) => {
      return source !== SOURCE.NW_GUMTREE;
    });
  }

  return sources;
}

function getAddSource(result) {
  const isEmail = result.query.includes('@');

  if (result.data.addSource.includes(SOURCE.LEAKED_DATASETS_OLD)) {
    return;
  }

  if (isEmail && result.data.addSource.includes(SOURCE.NW_GUMTREE)) {
    return
  }

  return result.data.addSource[0];
}
</script>

<template>
  <div class="tw-flex tw-flex-col tw-gap-y-6 tw-w-full">
    <div class="tw-flex tw-justify-between tw-gap-x-4">
      <div>
        <h1 class="tw-font-bold tw-text-3xl">Results</h1>
        <p class="tw-font-light">View previous results from profile searches, sort by columns and filter via the menu.</p>
      </div>

      <Button @click="showFilters = !showFilters" variant="outline" class="tw-self-end">
        <FilterIcon class="tw-inline md:tw-mr-2" :size="22" />
        <span class="tw-hidden md:tw-block">{{ showFilters ? 'Close' : 'Open' }} filters</span>
      </Button>
    </div>

    <TransitionExpand>
      <div v-if="showFilters">
        <ResultsFilter
          @search:input="handleSearch"
          @sources:change="handleSources"
          @started:change="(range) => handleRange('started', range)"
          @completed:change="(range) => handleRange('completed', range)"
        />
      </div>
    </TransitionExpand>

    <div>
      <!-- Header -->
      <ResultsHeader @sort="handleSort" :sort="store.filters.sort" class="tw-hidden md:tw-grid" />
      <Separator class="tw-my-2 tw-opacity-50 tw-hidden md:tw-block" />
      <div class="tw-flex tw-flex-col tw-gap-y-2">
        <!-- Row -->
        <ResultsRow v-for="result in store.results" :key="result.id" :result="result">
          <ProfileModule :profiles="result.data.profiles" />
          <RelatedEmailsNumbersModule
            :emails="result.data.dataLeaks.uniqueEmails"
            :phones="result.data.dataLeaks.uniquePhones"
            :query="result.query"
            :reference="result.reference"
            :sources="result.sourcesSearched"
          />
          <AdditionalInfoModule :networks="result.data.networks || []" :sources="result.sourcesMatched" />
          <GumtreeModule v-if="result.data.gumtree.length" :listings="result.data.gumtree" />
          <PiplModule v-if="result.data.pipl.data" :data="result.data.pipl" />
          <AddSourceModule
            v-if="getAddSource(result) && getAddSources(result).length === 1"
            :source="getAddSource(result)"
            :key="getAddSource(result)"
            @add:source="handleAddSources([$event], result)"
          />
          <AddMultiSourceModule
            v-if="getAddSources(result).length > 1"
            :sources="getAddSources(result)"
            :key="result.id + '-source-multi'"
            @add:sources="handleAddSources($event, result)"
          />
        </ResultsRow>
        <p v-if="store.results.length === 0" class="tw-text-center tw-text-muted-foreground tw-mt-2">No results available, have you created a search yet? 👆</p>
      </div>
    </div>

    <div class="tw-flex tw-justify-center" v-if="store.filters.hasNextPage">
      <Button @click="store.fetchNextPage">Load more results</Button>
    </div>
  </div>
</template>